<template>
  <div>
    <slot>
      <text-grid-row v-for="(item, i) in items" :key="i" :width="width">
        <text-grid-column v-for="(item2, j) in items[i]" :key="j">{{
          item2
        }}</text-grid-column>
      </text-grid-row>
    </slot>
  </div>
</template>
<script>
export default {
  name: "text-grid",
  components: {
    textGridRow: () => import("./textGridRow.vue"),
    textGridColumn: () => import("./textGridColumn.vue"),
  },
  props: {
    width: {
      type: Number,
      default: 400,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
